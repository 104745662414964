import React, { useState } from "react";
import ImageUploading from "react-images-uploading";

// partials
import Button from "./Button";

// services
import imageService from "../services/imageService";

export default function ImageUpload({ images, setImages, maxImages }) {
  const [isLoading, setIsLoading] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const onChange = async (imageList, addUpdateIndex) => {
    if (imageList.length > 0) {
      setIsLoading(true);
    }
    setImages(imageList);
    let newImageList = imageList;
    for (let i = 0; i < imageList.length; i++) {
      if (!imageList[i].url) {
        imageService
          .saveImage(imageList[i])
          .then((imageRes) => {
            console.log("imageRes", imageRes);
            newImageList[i]["url"] = imageRes.data.data.link;
            newImageList[i]["status"] = "Success";
            setImages(newImageList);
          })
          .catch((imageErr) => {
            console.log("imageErr", imageErr);
            newImageList[i]["status"] = "Failure";
            setImages(newImageList);
            setUploadError(true);
          });
      }
    }
    setTimeout(() => {
      setIsLoading(false);
    }, [15000]);
    // setIsLoading(false);
  };

  React.useEffect(() => {
    console.log(isLoading);
  }, [isLoading]);

  return (
    <div className="App">
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxImages}
        dataURLKey="dataURL"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div>
            {!isLoading ? (
              <button
                onClick={onImageUpload}
                type="button"
                className={"p-12 w-full border border-dashed border-3 rounded-md ".concat(
                  isDragging || uploadError
                    ? "border-red-300"
                    : "border-gray-300"
                )}
                {...dragProps}
              >
                {!uploadError ? (
                  <>
                    {images.length === 0 ? (
                      "Hier klicken oder Bilder reinziehen"
                    ) : (
                      <div className="relative grid grid-cols-2 gap-x-6 gap-y-6">
                        {imageList.map((image, index) => (
                          <div key={index} className="flex flex-row">
                            <div className="relative">
                              <img src={image.dataURL} alt="" width="100" />
                              <div className="absolute -top-3 -right-3">
                                <div
                                  className="rounded-full p-2 bg-gray-100/50 backdrop-blur-lg"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    onImageRemove(index);
                                  }}
                                >
                                  {isLoading ? (
                                    <div className="w-4 h-4 border-b-2 border-red-600 rounded-full animate-spin" />
                                  ) : image.status === "Failure" ? (
                                    "!"
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-6 w-6 text-red-600"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                      strokeWidth={2}
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                      />
                                    </svg>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="flex justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-10 w-10 text-yellow-500"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <p>
                      Leider gab es ein Problem beim Hochladen Ihrer Bilder.
                      Bitte probieren Sie es direkt noch einmal!
                    </p>
                    <Button
                      onClick={() => {
                        setIsLoading(false);
                        setUploadError(false);
                        setImages([]);
                      }}
                      buttonText="Neu hochladen"
                      bgColor="red-600"
                      bgHoverColor="red-700"
                      borderColor="transparent"
                      textColor="white"
                      fontWeight="medium"
                      px="8"
                      mt="6"
                      py="3"
                      ml="0"
                      width="full"
                      ringColor="red-500"
                    />
                  </>
                )}
              </button>
            ) : (
              <div className="flex flex-col items-center justify-center relative">
                <div className="w-32 h-32 border-b-2 border-red-600 rounded-full animate-spin" />
                <p className="text-base font-bold my-6 px-6">
                  {"Der Upload Ihrer Bilder läuft. Bitte warten Sie und laden Sie die Seite nicht neu."}
                </p>
              </div>
            )}
          </div>
        )}
      </ImageUploading>
    </div>
  );
}
