import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

// partials
import InputField from "../partials/InputField";
import Button from "../partials/Button";
import ImageUpload from "../partials/ImageUpload";

// assets
import SpringboardLogo from "../assets/Springboard_Logo_horizontal.png";

// services
import applicationService from "../services/applicationService";
import imageService from "../services/imageService";

const customerEmail =
  "<!DOCTYPE html>" +
  '<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">' +
  "<head>" +
  "	<title></title>" +
  '	<meta content="text/html; charset=utf-8" http-equiv="Content-Type" />' +
  '	<meta content="width=device-width, initial-scale=1.0" name="viewport" />' +
  "	<!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->" +
  "	<style>" +
  "		* {" +
  "			box-sizing: border-box;" +
  "		}" +
  "		body {" +
  "			margin: 0;" +
  "			padding: 0;" +
  "		}" +
  "		a[x-apple-data-detectors] {" +
  "			color: inherit !important;" +
  "			text-decoration: inherit !important;" +
  "		}" +
  "		#MessageViewBody a {" +
  "			color: inherit;" +
  "			text-decoration: none;" +
  "		}" +
  "		p {" +
  "			line-height: inherit" +
  "		}" +
  "		.desktop_hide," +
  "		.desktop_hide table {" +
  "			mso-hide: all;" +
  "			display: none;" +
  "			max-height: 0px;" +
  "			overflow: hidden;" +
  "		}" +
  "		@media (max-width:740px) {" +
  "			.desktop_hide table.icons-inner {" +
  "				display: inline-block !important;" +
  "			}" +
  "			.icons-inner {" +
  "				text-align: center;" +
  "			}" +
  "			.icons-inner td {" +
  "				margin: 0 auto;" +
  "			}" +
  "			.image_block img.big," +
  "			.row-content {" +
  "				width: 100% !important;" +
  "			}" +
  "			.mobile_hide {" +
  "				display: none;" +
  "			}" +
  "			.stack .column {" +
  "				width: 100%;" +
  "				display: block;" +
  "			}" +
  "			.mobile_hide {" +
  "				min-height: 0;" +
  "				max-height: 0;" +
  "				max-width: 0;" +
  "				overflow: hidden;" +
  "				font-size: 0px;" +
  "			}" +
  "			.desktop_hide," +
  "			.desktop_hide table {" +
  "				display: table !important;" +
  "				max-height: none !important;" +
  "			}" +
  "		}" +
  "	</style>" +
  "</head>" +
  '<body style="margin: 0; background-color: #ffffff; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">' +
  '	<table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"' +
  '		style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff;" width="100%">' +
  "		<tbody>" +
  "			<tr>" +
  "				<td>" +
  '					<table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"' +
  '						role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">' +
  "						<tbody>" +
  "							<tr>" +
  "								<td>" +
  '									<table align="center" border="0" cellpadding="0" cellspacing="0"' +
  '										class="row-content stack" role="presentation"' +
  '										style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 720px;"' +
  '										width="720">' +
  "										<tbody>" +
  "											<tr>" +
  '												<td class="column column-1"' +
  '													style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 5px; padding-bottom: 5px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"' +
  '													width="100%">' +
  '													<div class="spacer_block"' +
  '														style="height:25px;line-height:25px;font-size:1px;"> </div>' +
  "												</td>" +
  "											</tr>" +
  "										</tbody>" +
  "									</table>" +
  "								</td>" +
  "							</tr>" +
  "						</tbody>" +
  "					</table>" +
  '					<table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-2"' +
  '						role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">' +
  "						<tbody>" +
  "							<tr>" +
  "								<td>" +
  '									<table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content"' +
  '										role="presentation"' +
  '										style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 720px;"' +
  '										width="720">' +
  "										<tbody>" +
  "											<tr>" +
  '												<td class="column column-1"' +
  '													style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"' +
  '													width="50%">' +
  '													<table border="0" cellpadding="0" cellspacing="0"' +
  '														class="image_block" role="presentation"' +
  '														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"' +
  '														width="100%">' +
  "														<tr>" +
  "															<td" +
  '																style="padding-left:25px;padding-top:15px;width:100%;padding-right:0px;padding-bottom:5px;">' +
  '																<div style="line-height:10px"><img alt="Alternate text"' +
  '																		src="https://drive.google.com/uc?export=view&id=1TPPfoCAj0Fxy2xXa95lu_rddexLgvR3T"' +
  '																		style="display: block; height: auto; border: 0; width: 180px; max-width: 100%;"' +
  '																		title="Alternate text" width="180" /></div>' +
  "															</td>" +
  "														</tr>" +
  "													</table>" +
  "												</td>" +
  '												<td class="column column-2"' +
  '													style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"' +
  '													width="50%">' +
  '													<div class="spacer_block"' +
  '														style="height:55px;line-height:5px;font-size:1px;"> </div>' +
  "												</td>" +
  "											</tr>" +
  "										</tbody>" +
  "									</table>" +
  "								</td>" +
  "							</tr>" +
  "						</tbody>" +
  "					</table>" +
  '					<table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-3"' +
  '						role="presentation"' +
  '						style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff;" width="100%">' +
  "						<tbody>" +
  "							<tr>" +
  "								<td>" +
  '									<table align="center" border="0" cellpadding="0" cellspacing="0"' +
  '										class="row-content stack" role="presentation"' +
  '										style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff; background-position: top center; color: #000000; width: 720px;"' +
  '										width="720">' +
  "										<tbody>" +
  "											<tr>" +
  '												<td class="column column-1"' +
  '													style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"' +
  '													width="50%">' +
  '													<table border="0" cellpadding="0" cellspacing="0" class="text_block"' +
  '														role="presentation"' +
  '														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"' +
  '														width="100%">' +
  "														<tr>" +
  "															<td" +
  '																style="padding-bottom:10px;padding-left:25px;padding-right:25px;padding-top:45px;">' +
  '																<div style="font-family: sans-serif">' +
  '																	<div class="txtTinyMce-wrapper"' +
  '																		style="font-size: 12px; mso-line-height-alt: 14.399999999999999px; color: #1f0b0b; line-height: 1.2; font-family: Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif;">' +
  '																		<p style="margin: 0; font-size: 14px;"><strong' +
  '																				style="font-size:38px;font-family:inherit;font-family:inherit;"><span' +
  '																					style="">Gemeinsam für' +
  "																					Münster.</span></strong></p>" +
  "																	</div>" +
  "																</div>" +
  "															</td>" +
  "														</tr>" +
  "													</table>" +
  '													<table border="0" cellpadding="0" cellspacing="0" class="text_block"' +
  '														role="presentation"' +
  '														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"' +
  '														width="100%">' +
  "														<tr>" +
  "															<td" +
  '																style="padding-bottom:30px;padding-left:25px;padding-right:25px;padding-top:10px;">' +
  '																<div style="font-family: sans-serif">' +
  '																	<div class="txtTinyMce-wrapper"' +
  '																		style="font-size: 12px; mso-line-height-alt: 21.6px; color: #393d47; line-height: 1.8; font-family: Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif;">' +
  '																		<p style="margin: 0; font-size: 14px;">Hiermit' +
  "																			bestätigen wir Ihnen den Eingang Ihrer" +
  "																			Bewerbung, welche für Sie auch in dieser" +
  "																			E-Mail angehängt ist.</p>" +
  "																	</div>" +
  "																</div>" +
  "															</td>" +
  "														</tr>" +
  "													</table>" +
  "												</td>" +
  '												<td class="column column-2"' +
  '													style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"' +
  '													width="50%">' +
  '													<table border="0" cellpadding="0" cellspacing="0"' +
  '														class="image_block" role="presentation"' +
  '														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"' +
  '														width="100%">' +
  "														<tr>" +
  "															<td" +
  '																style="padding-right:5px;width:100%;padding-left:0px;padding-top:25px;">' +
  '																<div align="center" style="line-height:10px"><img' +
  '																		alt="Alternate text" class="big"' +
  '																		src="https://drive.google.com/uc?export=view&id=13QqIsVbPNk8McF8D2OwOHqj1Rgw_hf8y"' +
  '																		style="display: block; height: auto; border: 0; width: 355px; max-width: 100%;"' +
  '																		title="Alternate text" width="355" /></div>' +
  "															</td>" +
  "														</tr>" +
  "													</table>" +
  '													<div class="spacer_block"' +
  '														style="height:40px;line-height:40px;font-size:1px;"> </div>' +
  '													<div class="spacer_block mobile_hide"' +
  '														style="height:40px;line-height:40px;font-size:1px;"> </div>' +
  '													<div class="spacer_block"' +
  '														style="height:5px;line-height:5px;font-size:1px;"> </div>' +
  "												</td>" +
  "											</tr>" +
  "										</tbody>" +
  "									</table>" +
  "								</td>" +
  "							</tr>" +
  "						</tbody>" +
  "					</table>" +
  '					<table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-4"' +
  '						role="presentation"' +
  '						style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #e4faf4;" width="100%">' +
  "						<tbody>" +
  "							<tr>" +
  "								<td>" +
  '									<table align="center" border="0" cellpadding="0" cellspacing="0"' +
  '										class="row-content stack" role="presentation"' +
  '										style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 720px;"' +
  '										width="720">' +
  "										<tbody>" +
  "											<tr>" +
  '												<td class="column column-1"' +
  '													style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"' +
  '													width="50%">' +
  '													<div class="spacer_block"' +
  '														style="height:25px;line-height:20px;font-size:1px;"> </div>' +
  '													<div class="spacer_block mobile_hide"' +
  '														style="height:20px;line-height:20px;font-size:1px;"> </div>' +
  '													<table border="0" cellpadding="0" cellspacing="0" class="text_block"' +
  '														role="presentation"' +
  '														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"' +
  '														width="100%">' +
  "														<tr>" +
  "															<td" +
  '																style="padding-bottom:15px;padding-left:35px;padding-right:35px;padding-top:5px;">' +
  '																<div style="font-family: sans-serif">' +
  '																	<div class="txtTinyMce-wrapper"' +
  '																		style="font-size: 12px; mso-line-height-alt: 18px; color: #34495e; line-height: 1.5; font-family: Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif;">' +
  "																		<p" +
  '																			style="margin: 0; font-size: 14px; text-align: center; mso-line-height-alt: 30px;">' +
  '																			<span style="font-size:20px;"><strong><span' +
  '																						style="">Freundlich unterstützt' +
  "																						durch</span></strong></span>" +
  "																		</p>" +
  "																	</div>" +
  "																</div>" +
  "															</td>" +
  "														</tr>" +
  "													</table>" +
  "												</td>" +
  '												<td class="column column-2"' +
  '													style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"' +
  '													width="50%">' +
  '													<div class="spacer_block"' +
  '														style="height:5px;line-height:5px;font-size:1px;"> </div>' +
  '													<div class="spacer_block mobile_hide"' +
  '														style="height:20px;line-height:20px;font-size:1px;"> </div>' +
  '													<table border="0" cellpadding="0" cellspacing="0"' +
  '														class="image_block" role="presentation"' +
  '														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"' +
  '														width="100%">' +
  "														<tr>" +
  "															<td" +
  '																style="padding-bottom:40px;padding-left:15px;padding-right:15px;width:100%;">' +
  '																<div align="center" style="line-height:10px"> <a href="https://spring-board.dev"><img' +
  '																		alt="Alternate text"' +
  '																		src="https://oberurselcard-shop.spring-board.dev/static/media/Springboard_Logo_horizontal.e26f26ca.png"' +
  '																		style="display: block; height: auto; border: 0; width: 180px; max-width: 100%;"' +
  '																		title="Alternate text" width="180" /></div></a>' +
  "															</td>" +
  "														</tr>" +
  "													</table>" +
  "												</td>" +
  "											</tr>" +
  "										</tbody>" +
  "									</table>" +
  "								</td>" +
  "							</tr>" +
  "						</tbody>" +
  "					</table>" +
  "				</td>" +
  "			</tr>" +
  "		</tbody>" +
  "	</table><!-- End -->" +
  "</body>" +
  "</html>";

const stiftungEmail =
  "<!DOCTYPE html>" +
  '<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">' +
  "<head>" +
  "	<title></title>" +
  '	<meta content="text/html; charset=utf-8" http-equiv="Content-Type" />' +
  '	<meta content="width=device-width, initial-scale=1.0" name="viewport" />' +
  "	<!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->" +
  "	<style>" +
  "		* {" +
  "			box-sizing: border-box;" +
  "		}" +
  "		body {" +
  "			margin: 0;" +
  "			padding: 0;" +
  "		}" +
  "		a[x-apple-data-detectors] {" +
  "			color: inherit !important;" +
  "			text-decoration: inherit !important;" +
  "		}" +
  "		#MessageViewBody a {" +
  "			color: inherit;" +
  "			text-decoration: none;" +
  "		}" +
  "		p {" +
  "			line-height: inherit" +
  "		}" +
  "		.desktop_hide," +
  "		.desktop_hide table {" +
  "			mso-hide: all;" +
  "			display: none;" +
  "			max-height: 0px;" +
  "			overflow: hidden;" +
  "		}" +
  "		@media (max-width:740px) {" +
  "			.desktop_hide table.icons-inner {" +
  "				display: inline-block !important;" +
  "			}" +
  "			.icons-inner {" +
  "				text-align: center;" +
  "			}" +
  "			.icons-inner td {" +
  "				margin: 0 auto;" +
  "			}" +
  "			.image_block img.big," +
  "			.row-content {" +
  "				width: 100% !important;" +
  "			}" +
  "			.mobile_hide {" +
  "				display: none;" +
  "			}" +
  "			.stack .column {" +
  "				width: 100%;" +
  "				display: block;" +
  "			}" +
  "			.mobile_hide {" +
  "				min-height: 0;" +
  "				max-height: 0;" +
  "				max-width: 0;" +
  "				overflow: hidden;" +
  "				font-size: 0px;" +
  "			}" +
  "			.desktop_hide," +
  "			.desktop_hide table {" +
  "				display: table !important;" +
  "				max-height: none !important;" +
  "			}" +
  "		}" +
  "	</style>" +
  "</head>" +
  '<body style="margin: 0; background-color: #ffffff; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">' +
  '	<table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"' +
  '		style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff;" width="100%">' +
  "		<tbody>" +
  "			<tr>" +
  "				<td>" +
  '					<table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"' +
  '						role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">' +
  "						<tbody>" +
  "							<tr>" +
  "								<td>" +
  '									<table align="center" border="0" cellpadding="0" cellspacing="0"' +
  '										class="row-content stack" role="presentation"' +
  '										style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 720px;"' +
  '										width="720">' +
  "										<tbody>" +
  "											<tr>" +
  '												<td class="column column-1"' +
  '													style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 5px; padding-bottom: 5px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"' +
  '													width="100%">' +
  '													<div class="spacer_block"' +
  '														style="height:25px;line-height:25px;font-size:1px;"> </div>' +
  "												</td>" +
  "											</tr>" +
  "										</tbody>" +
  "									</table>" +
  "								</td>" +
  "							</tr>" +
  "						</tbody>" +
  "					</table>" +
  '					<table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-2"' +
  '						role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">' +
  "						<tbody>" +
  "							<tr>" +
  "								<td>" +
  '									<table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content"' +
  '										role="presentation"' +
  '										style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 720px;"' +
  '										width="720">' +
  "										<tbody>" +
  "											<tr>" +
  '												<td class="column column-1"' +
  '													style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"' +
  '													width="50%">' +
  '													<table border="0" cellpadding="0" cellspacing="0"' +
  '														class="image_block" role="presentation"' +
  '														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"' +
  '														width="100%">' +
  "														<tr>" +
  "															<td" +
  '																style="padding-left:25px;padding-top:15px;width:100%;padding-right:0px;padding-bottom:5px;">' +
  '																<div style="line-height:10px"><img alt="Alternate text"' +
  '																		src="https://drive.google.com/uc?export=view&id=1TPPfoCAj0Fxy2xXa95lu_rddexLgvR3T"' +
  '																		style="display: block; height: auto; border: 0; width: 180px; max-width: 100%;"' +
  '																		title="Alternate text" width="180" /></div>' +
  "															</td>" +
  "														</tr>" +
  "													</table>" +
  "												</td>" +
  '												<td class="column column-2"' +
  '													style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"' +
  '													width="50%">' +
  '													<div class="spacer_block"' +
  '														style="height:55px;line-height:5px;font-size:1px;"> </div>' +
  "												</td>" +
  "											</tr>" +
  "										</tbody>" +
  "									</table>" +
  "								</td>" +
  "							</tr>" +
  "						</tbody>" +
  "					</table>" +
  '					<table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-3"' +
  '						role="presentation"' +
  '						style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff;" width="100%">' +
  "						<tbody>" +
  "							<tr>" +
  "								<td>" +
  '									<table align="center" border="0" cellpadding="0" cellspacing="0"' +
  '										class="row-content stack" role="presentation"' +
  '										style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff; background-position: top center; color: #000000; width: 720px;"' +
  '										width="720">' +
  "										<tbody>" +
  "											<tr>" +
  '												<td class="column column-1"' +
  '													style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"' +
  '													width="50%">' +
  '													<table border="0" cellpadding="0" cellspacing="0" class="text_block"' +
  '														role="presentation"' +
  '														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"' +
  '														width="100%">' +
  "														<tr>" +
  "															<td" +
  '																style="padding-bottom:10px;padding-left:25px;padding-right:25px;padding-top:45px;">' +
  '																<div style="font-family: sans-serif">' +
  '																	<div class="txtTinyMce-wrapper"' +
  '																		style="font-size: 12px; mso-line-height-alt: 14.399999999999999px; color: #1f0b0b; line-height: 1.2; font-family: Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif;">' +
  '																		<p style="margin: 0; font-size: 14px;"><strong' +
  '																				style="font-size:38px;font-family:inherit;font-family:inherit;"><span' +
  '																					style="">Eingang neuer' +
  "																					Bewerbung.</span></strong></p>" +
  "																	</div>" +
  "																</div>" +
  "															</td>" +
  "														</tr>" +
  "													</table>" +
  '													<table border="0" cellpadding="0" cellspacing="0" class="text_block"' +
  '														role="presentation"' +
  '														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"' +
  '														width="100%">' +
  "														<tr>" +
  "															<td" +
  '																style="padding-bottom:30px;padding-left:25px;padding-right:25px;padding-top:10px;">' +
  '																<div style="font-family: sans-serif">' +
  '																	<div class="txtTinyMce-wrapper"' +
  '																		style="font-size: 12px; mso-line-height-alt: 21.6px; color: #393d47; line-height: 1.8; font-family: Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif;">' +
  '																		<p style="margin: 0; font-size: 14px;">Soeben' +
  "																			wurde eine neue Bewerbung erstellt, das" +
  "																			entsprechende PDF ist dieser Mail angehängt." +
  "																		</p>" +
  "																	</div>" +
  "																</div>" +
  "															</td>" +
  "														</tr>" +
  "													</table>" +
  "												</td>" +
  '												<td class="column column-2"' +
  '													style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"' +
  '													width="50%">' +
  '													<table border="0" cellpadding="0" cellspacing="0"' +
  '														class="image_block" role="presentation"' +
  '														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"' +
  '														width="100%">' +
  "														<tr>" +
  "															<td" +
  '																style="padding-right:5px;width:100%;padding-left:0px;padding-top:25px;">' +
  '																<div align="center" style="line-height:10px"><img' +
  '																		alt="Alternate text" class="big"' +
  '																		src="https://drive.google.com/uc?export=view&id=13QqIsVbPNk8McF8D2OwOHqj1Rgw_hf8y"' +
  '																		style="display: block; height: auto; border: 0; width: 355px; max-width: 100%;"' +
  '																		title="Alternate text" width="355" /></div>' +
  "															</td>" +
  "														</tr>" +
  "													</table>" +
  '													<div class="spacer_block"' +
  '														style="height:40px;line-height:40px;font-size:1px;"> </div>' +
  '													<div class="spacer_block mobile_hide"' +
  '														style="height:40px;line-height:40px;font-size:1px;"> </div>' +
  '													<div class="spacer_block"' +
  '														style="height:5px;line-height:5px;font-size:1px;"> </div>' +
  "												</td>" +
  "											</tr>" +
  "										</tbody>" +
  "									</table>" +
  "								</td>" +
  "							</tr>" +
  "						</tbody>" +
  "					</table>" +
  '					<table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-4"' +
  '						role="presentation"' +
  '						style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #e4faf4;" width="100%">' +
  "						<tbody>" +
  "							<tr>" +
  "								<td>" +
  '									<table align="center" border="0" cellpadding="0" cellspacing="0"' +
  '										class="row-content stack" role="presentation"' +
  '										style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 720px;"' +
  '										width="720">' +
  "										<tbody>" +
  "											<tr>" +
  '												<td class="column column-1"' +
  '													style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"' +
  '													width="50%">' +
  '													<div class="spacer_block"' +
  '														style="height:25px;line-height:20px;font-size:1px;"> </div>' +
  '													<div class="spacer_block mobile_hide"' +
  '														style="height:20px;line-height:20px;font-size:1px;"> </div>' +
  '													<table border="0" cellpadding="0" cellspacing="0" class="text_block"' +
  '														role="presentation"' +
  '														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"' +
  '														width="100%">' +
  "														<tr>" +
  "															<td" +
  '																style="padding-bottom:15px;padding-left:35px;padding-right:35px;padding-top:5px;">' +
  '																<div style="font-family: sans-serif">' +
  '																	<div class="txtTinyMce-wrapper"' +
  '																		style="font-size: 12px; mso-line-height-alt: 18px; color: #34495e; line-height: 1.5; font-family: Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif;">' +
  "																		<p" +
  '																			style="margin: 0; font-size: 14px; text-align: center; mso-line-height-alt: 30px;">' +
  '																			<span style="font-size:20px;"><strong><span' +
  '																						style="">Freundlich unterstützt' +
  "																						durch</span></strong></span>" +
  "																		</p>" +
  "																	</div>" +
  "																</div>" +
  "															</td>" +
  "														</tr>" +
  "													</table>" +
  "												</td>" +
  '												<td class="column column-2"' +
  '													style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"' +
  '													width="50%">' +
  '													<div class="spacer_block"' +
  '														style="height:5px;line-height:5px;font-size:1px;"> </div>' +
  '													<div class="spacer_block mobile_hide"' +
  '														style="height:20px;line-height:20px;font-size:1px;"> </div>' +
  '													<table border="0" cellpadding="0" cellspacing="0"' +
  '														class="image_block" role="presentation"' +
  '														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"' +
  '														width="100%">' +
  "														<tr>" +
  "															<td" +
  '																style="padding-bottom:40px;padding-left:15px;padding-right:15px;width:100%;">' +
  '																<div align="center" style="line-height:10px"> <a href="https://spring-board.dev"><img' +
  '																		alt="Alternate text"' +
  '																		src="https://oberurselcard-shop.spring-board.dev/static/media/Springboard_Logo_horizontal.e26f26ca.png"' +
  '																		style="display: block; height: auto; border: 0; width: 180px; max-width: 100%;"' +
  '																		title="Alternate text" width="180" /></div></a>' +
  "															</td>" +
  "														</tr>" +
  "													</table>" +
  "												</td>" +
  "											</tr>" +
  "										</tbody>" +
  "									</table>" +
  "								</td>" +
  "							</tr>" +
  "						</tbody>" +
  "					</table>" +
  "				</td>" +
  "			</tr>" +
  "		</tbody>" +
  "	</table><!-- End -->" +
  "</body>" +
  "</html>";

export default function Survey() {
  let history = useHistory();
  // states
  const [name, setName] = useState("");
  const [counterName, setCounterName] = useState(0);
  const [projectName, setProjectName] = useState("");
  const [counterProjectName, setCounterProjectName] = useState(0);
  const [projectURL, setProjectURL] = useState("");
  const [counterProjectURL, setCounterProjectURL] = useState(0);
  const [contactPerson, setContactPerson] = useState("");
  const [counterContactPerson, setCounterContactPerson] = useState(0);
  const [street, setStreet] = useState("");
  const [counterStreet, setCounterStreet] = useState(0);
  const [houseNumber, setHouseNumber] = useState("");
  const [counterHouseNumber, setCounterHouseNumber] = useState(0);
  const [zipCode, setZipCode] = useState("");
  const [counterZipCode, setCounterZipCode] = useState(0);
  const [city, setCity] = useState("");
  const [counterCity, setCounterCity] = useState(0);
  const [email, setEmail] = useState("");
  const [counterEmail, setCounterEmail] = useState(0);
  const [telephone, setTelephone] = useState("");
  const [projectIdea, setProjectIdea] = useState("");
  const [counterProjectIdea, setCounterProjectIdea] = useState(0);
  const [region, setRegion] = useState("");
  const [counterRegion, setCounterRegion] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [currentVolunteerCount, setCurrentVolunteerCount] = useState("");
  const [counterCurrentVolunteerCount, setCounterCurrentVolunteerCount] =
    useState(0);
  const [errorCurrentVolunteerCount, setErrorCurrentVolunteerCount] =
    useState();
  const [entireVolunteerCount, setEntireVolunteerCount] = useState("");
  const [counterEntireVolunteerCount, setCounterEntireVolunteerCount] =
    useState(0);
  const [errorEntireVolunteerCount, setErrorEntireVolunteerCount] = useState();
  const [currentEmployeeCount, setCurrentEmployeeCount] = useState("");
  const [counterCurrentEmployeeCount, setCounterCurrentEmployeeCount] =
    useState(0);
  const [errorCurrentEmployeeCount, setErrorCurrentEmployeeCount] = useState();
  const [occasion, setOccasion] = useState("");
  const [counterOccasion, setCounterOccasion] = useState(0);
  const [projectAim, setProjectAim] = useState("");
  const [counterProjectAim, setCounterProjectAim] = useState(0);
  const [cooperationPartner, setCooperationPartner] = useState("");
  const [counterCooperationPartner, setCounterCooperationPartner] = useState(0);
  const [achievedSoFar, setAchievedSoFar] = useState("");
  const [counterAchievedSoFar, setCounterAchievedSoFar] = useState(0);
  const [projectFinancing, setProjectFinancing] = useState("");
  const [counterProjectFinancing, setCounterProjectFinancing] = useState(0);
  const [projectUSP, setProjectUSP] = useState("");
  const [counterProjectUSP, setCounterProjectUSP] = useState(0);
  const [projectOutlook, setProjectOutlook] = useState("");
  const [counterProjectOutlook, setCounterProjectOutlook] = useState(0);
  const [ToSAgreement, setToSAgreement] = useState(false);
  const [marketingChannel, setMarketingChannel] = useState("");
  const [counterMarketingChannel, setCounterMarketingChannel] = useState(0);
  const [images, setImages] = useState([]);
  const [imageCount, setImageCount] = useState(0);
  const maxImages = 5;

  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(
    "Wir verarbeiten Ihre Anfrage..."
  );

  useEffect(() => {
    const curImageCount = images.length;
    setImageCount(curImageCount);
  }, [images]);

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setLoadingText("Sie erhalten gleich eine E-Mail mit Ihrer Bewerbung");
      }, 5000);
      setTimeout(() => {
        setLoadingText("Wir wünschen Ihnen viel Erfolg bei Ihrer Bewerbung!");
      }, 10000);
    } else {
      setLoadingText("Wir verarbeiten Ihre Anfrage...");
    }
  }, [isLoading]);

  // styles
  const inputStyle =
    "w-full px-2 lg:px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-red-100 focus:border-red-300";
  const checkbox_inputStyle =
    "border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-red-100 focus:border-red-300 scale-150";
  // const checkboxInputStyle =
  //   "h-5 w-5 text-red-600 rounded-md focus:outline-none focus:ring focus:ring-red-100 focus:border-red-300";
  const labelStyle = "block mb-1 text-sm font-medium text-gray-600";
  const checkbox_labelStyle =
    "flex flex-row jsutify-between items-center block mb-1 text-sm font-medium text-gray-600";
  // const checkboxLabelStyle = "inline-flex items-center my-3";
  const headerStyle = "mb-3 mt-5 text-xl font-semibold text-gray-700";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // 1. Upload images to imgur
    // let imageUrls = await Promise.all(
    //   images.map(async (image) => {
    //     const res = await imageService.saveImage(image);
    //     return res.data.data.link;
    //   })
    // );
    // 1. Get image Urls from state
    let imageUrls = images.map((image) => image.url);
    console.log("imageUrls", imageUrls);
    // 2. Save to DB
    const application = {
      name: name,
      projectName: projectName,
      projectURL: projectURL,
      contactPerson: contactPerson,
      street: street,
      houseNumber: houseNumber,
      zipCode: zipCode,
      city: city,
      email: email,
      telephone: telephone,
      projectIdea: projectIdea,
      region: region,
      startDate: startDate,
      currentVolunteerCount: currentVolunteerCount,
      entireVolunteerCount: entireVolunteerCount,
      currentEmployeeCount: currentEmployeeCount,
      occasion: occasion,
      projectAim: projectAim,
      cooperationPartner: cooperationPartner,
      achievedSoFar: achievedSoFar,
      projectFinancing: projectFinancing,
      projectUSP: projectUSP,
      projectOutlook: projectOutlook,
      ToSAgreement: ToSAgreement,
      marketingChannel: marketingChannel,
      images: imageUrls,
    };
    const saveReq = applicationService.saveApplication(application);
    // 3. Send emails
    saveReq.then(async (saveRes) => {
      let attachments = [
        {
          type: "pdf",
          url:
            "https://buergerpreis-muenster.spring-board.dev/resume/" +
            saveRes.data.data._id,
          filename: "Zusammenfassung Bewerbung Bürgerpreis Münster",
        },
      ];
      saveRes.data.data.images.forEach((imageUrl, index) => {
        attachments.push({
          type: "image",
          url: imageUrl,
          filename: `Bild ${index + 1} - Bewerbung Nr. ${
            saveRes.data.data.number
          }`,
        });
      });
      // To customer
      const emailData = {
        recipient: [email],
        subject: "Eingangsbestätigung - Bewerbung Bürgerpreis Münster",
        sender: "info@spring-board.dev",
        content: customerEmail,
        attachments: attachments,
      };
      console.log("emailData: ", emailData);
      await axios.post(
        "https://springboard-email.azurewebsites.net/api/emails",
        // "https://springboard-email-test.azurewebsites.net/api/emails",
        emailData,
        {
          headers: {
            "x-functions-key":
              "XoA6FrxbqXn0Cn69e7taieVrvCmL5O7jXpeEVS9SCkWmVfQLiByi4w==",
          },
        }
      );
      // To stiftung
      const emailData2 = {
        recipient: ["kontakt@buerger-fuer-muenster.de"],
        // recipient: ["info@spring-board.dev"],
        subject: "Eingangsbestätigung - Bewerbung Bürgerpreis Münster",
        sender: "info@spring-board.dev",
        content: stiftungEmail,
        attachments: attachments,
      };
      console.log("emailData2: ", emailData2);
      await axios.post(
        "https://springboard-email.azurewebsites.net/api/emails",
        // "https://springboard-email-test.azurewebsites.net/api/emails",
        emailData2,
        {
          headers: {
            "x-functions-key":
              "XoA6FrxbqXn0Cn69e7taieVrvCmL5O7jXpeEVS9SCkWmVfQLiByi4w==",
          },
        }
      );
      setIsLoading(false);
      history.push("/success");
    });
  };

  const setTheInputName = (e) => {
    setName(e);
    setCounterName(e.length);
  };

  const setTheInputProjectName = (e) => {
    setProjectName(e);
    setCounterProjectName(e.length);
  };

  const setTheInputProjectURL = (e) => {
    setProjectURL(e);
    setCounterProjectURL(e.length);
  };

  const setTheInputContactPerson = (e) => {
    setContactPerson(e);
    setCounterContactPerson(e.length);
  };

  const setTheInputStreet = (e) => {
    setStreet(e);
    setCounterStreet(e.length);
  };

  const setTheInputHouseNumber = (e) => {
    setHouseNumber(e);
    setCounterHouseNumber(e.length);
  };

  const setTheInputZipCode = (e) => {
    setZipCode(e);
    setCounterZipCode(e.length);
  };

  const setTheInputCity = (e) => {
    setCity(e);
    setCounterCity(e.length);
  };

  const setTheInputEmail = (e) => {
    setEmail(e);
    setCounterEmail(e.length);
  };

  const setTheInputProjectIdea = (e) => {
    setProjectIdea(e);
    setCounterProjectIdea(e.length);
  };

  const setTheInputRegion = (e) => {
    setRegion(e);
    setCounterRegion(e.length);
  };

  const setTheInputCurrentVolunteerCount = (e) => {
    if (!isNaN(+e)) {
      // true if its a number, false if not
      setErrorCurrentVolunteerCount(true);
    } else {
      setErrorCurrentVolunteerCount(false);
    }
    setCurrentVolunteerCount(e);
    setCounterCurrentVolunteerCount(e.length);
  };

  const setTheInputEntireVolunteerCount = (e) => {
    if (!isNaN(+e)) {
      // true if its a number, false if not
      setErrorEntireVolunteerCount(true);
    } else {
      setErrorEntireVolunteerCount(false);
    }
    setEntireVolunteerCount(e);
    setCounterEntireVolunteerCount(e.length);
  };

  const setTheInputCurrentEmployeeCount = (e) => {
    if (!isNaN(+e)) {
      // true if its a number, false if not
      setErrorCurrentEmployeeCount(true);
    } else {
      setErrorCurrentEmployeeCount(false);
    }
    setCurrentEmployeeCount(e);
    setCounterCurrentEmployeeCount(e.length);
  };

  const setTheInputOccasion = (e) => {
    setOccasion(e);
    setCounterOccasion(e.length);
  };

  const setTheInputProjectAim = (e) => {
    setProjectAim(e);
    setCounterProjectAim(e.length);
  };

  const setTheInputCooperationPartner = (e) => {
    setCooperationPartner(e);
    setCounterCooperationPartner(e.length);
  };

  const setTheInputAchievedSoFar = (e) => {
    setAchievedSoFar(e);
    setCounterAchievedSoFar(e.length);
  };

  const setTheInputProjectFinancing = (e) => {
    setProjectFinancing(e);
    setCounterProjectFinancing(e.length);
  };

  const setTheInputProjectUSP = (e) => {
    setProjectUSP(e);
    setCounterProjectUSP(e.length);
  };

  const setTheInputProjectOutlook = (e) => {
    setProjectOutlook(e);
    setCounterProjectOutlook(e.length);
  };

  const setTheInputMarketingChannel = (e) => {
    setMarketingChannel(e);
    setCounterMarketingChannel(e.length);
  };

  return (
    <div className="rounded-b-lg lg:rounded-b-none lg:rounded-l-lg">
      <div className="flex items-center bg-gray-50 rounded-b-lg lg:rounded-b-none lg:rounded-l-lg">
        <div className="container mx-auto">
          <div className="max-w-lg mx-auto my-10 bg-white p-5 rounded-lg shadow-sm">
            {/* Loading animation */}
            {isLoading ? (
              <div className="bg-gray-50 py-16 px-4 sm:px-6 sm:flex sm:flex-col justify-center absolute inset-6">
                <div className="flex flex-col items-center justify-center relative">
                  <div className="w-32 h-32 border-b-2 border-red-600 rounded-full animate-spin" />
                  <p className="text-lg font-bold mt-6">{loadingText}</p>
                </div>
              </div>
            ) : (
              <form onSubmit={(e) => handleSubmit(e)}>
                <h1 className={headerStyle}>Allgemeine Informationen</h1>
                <div className="flex flex-col m-4 gap-y-4">
                  {/* Institution name */}
                  <InputField
                    isTextarea={false}
                    isCheckbox={false}
                    labelFor={"institutionName"}
                    labelName={
                      "Name des Vereins/der Institution/des Trägers/der Initiative"
                    }
                    isRequired={true}
                    labelStyle={labelStyle}
                    inputType={"text"}
                    inputName={"institutionName"}
                    inputId={"institutionName"}
                    theInput={name}
                    setTheInput={setTheInputName}
                    inputStyle={inputStyle}
                    maxLength={60}
                    rows={1}
                    count={counterName}
                    hasError={false}
                    autocomplete={"organization"}
                  />
                  {/* Project name */}
                  <InputField
                    isTextarea={false}
                    isCheckbox={false}
                    labelFor={"projectName"}
                    labelName={"Name des Projektes"}
                    isRequired={true}
                    labelStyle={labelStyle}
                    inputType={"text"}
                    inputName={"projectName"}
                    inputId={"projectName"}
                    theInput={projectName}
                    setTheInput={setTheInputProjectName}
                    inputStyle={inputStyle}
                    maxLength={60}
                    rows={1}
                    count={counterProjectName}
                    hasError={false}
                    autocomplete={"off"}
                  />
                  {/* Project URL */}
                  <InputField
                    isTextarea={false}
                    isCheckbox={false}
                    labelFor={"projectUrl"}
                    labelName={
                      "Internet-Adresse für das Projekt/die Initiative"
                    }
                    isRequired={true}
                    labelStyle={labelStyle}
                    inputType={"text"}
                    inputName={"projectUrl"}
                    inputId={"projectUrl"}
                    theInput={projectURL}
                    setTheInput={setTheInputProjectURL}
                    inputStyle={inputStyle}
                    maxLength={128}
                    rows={1}
                    count={counterProjectURL}
                    hasError={false}
                    autocomplete={"url"}
                  />
                  {/* Contact person */}
                  <InputField
                    isTextarea={false}
                    isCheckbox={false}
                    labelFor={"contactPerson"}
                    labelName={"Ansprechpartner/Verantwortliche"}
                    isRequired={true}
                    labelStyle={labelStyle}
                    inputType={"text"}
                    inputName={"contactPerson"}
                    inputId={"contactPerson"}
                    theInput={contactPerson}
                    setTheInput={setTheInputContactPerson}
                    inputStyle={inputStyle}
                    maxLength={60}
                    rows={1}
                    count={counterContactPerson}
                    hasError={false}
                    autocomplete={"name"}
                  />
                  <div className="grid grid-cols-8 gap-2 lg:gap-4">
                    <div className="col-span-5">
                      {/* Street */}
                      <InputField
                        isTextarea={false}
                        isCheckbox={false}
                        labelFor={"street"}
                        labelName={"Straße"}
                        isRequired={true}
                        labelStyle={labelStyle}
                        inputType={"text"}
                        inputName={"street"}
                        inputId={"street"}
                        theInput={street}
                        setTheInput={setTheInputStreet}
                        inputStyle={inputStyle}
                        maxLength={60}
                        rows={1}
                        count={counterStreet}
                        hasError={false}
                        autocomplete={"address-line1"}
                      />
                    </div>
                    {/* Housenumber */}
                    <div className="col-span-3">
                      <InputField
                        isTextarea={false}
                        isCheckbox={false}
                        labelFor={"housenumber"}
                        labelName={"Hausnr."}
                        isRequired={true}
                        labelStyle={labelStyle}
                        inputType={"text"}
                        inputName={"housenumber"}
                        inputId={"housenumber"}
                        theInput={houseNumber}
                        setTheInput={setTheInputHouseNumber}
                        inputStyle={inputStyle}
                        maxLength={10}
                        rows={1}
                        count={counterHouseNumber}
                        hasError={false}
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-8 gap-2 lg:gap-4">
                    <div className="col-span-3">
                      {/* Zip code */}
                      <InputField
                        isTextarea={false}
                        isCheckbox={false}
                        labelFor={"zipCode"}
                        labelName={"PLZ"}
                        isRequired={true}
                        labelStyle={labelStyle}
                        inputType={"text"}
                        inputName={"zipCode"}
                        inputId={"zipCode"}
                        theInput={zipCode}
                        setTheInput={setTheInputZipCode}
                        inputStyle={inputStyle}
                        maxLength={10}
                        rows={1}
                        count={counterZipCode}
                        hasError={false}
                        autocomplete={"postal-code"}
                      />
                    </div>
                    <div className="col-span-5">
                      {/* City */}
                      <InputField
                        isTextarea={false}
                        isCheckbox={false}
                        labelFor={"city"}
                        labelName={"Ort"}
                        isRequired={true}
                        labelStyle={labelStyle}
                        inputType={"text"}
                        inputName={"city"}
                        inputId={"city"}
                        theInput={city}
                        setTheInput={setTheInputCity}
                        inputStyle={inputStyle}
                        maxLength={60}
                        rows={1}
                        count={counterCity}
                        hasError={false}
                        autocomplete={"address-level2"}
                      />
                    </div>
                  </div>
                  {/* E-Mail */}
                  <InputField
                    isTextarea={false}
                    isCheckbox={false}
                    labelFor={"email"}
                    labelName={"E-Mail"}
                    isRequired={true}
                    labelStyle={labelStyle}
                    inputType={"text"}
                    inputName={"email"}
                    inputId={"email"}
                    theInput={email}
                    setTheInput={setTheInputEmail}
                    inputStyle={inputStyle}
                    maxLength={128}
                    rows={1}
                    count={counterEmail}
                    hasError={false}
                    autocomplete={"email"}
                  />
                  {/* Telefon */}
                  <InputField
                    isTextarea={false}
                    isCheckbox={false}
                    labelFor={"phone"}
                    labelName={"Telefon"}
                    isRequired={false}
                    labelStyle={labelStyle}
                    inputType={"text"}
                    inputName={"phone"}
                    inputId={"phone"}
                    theInput={telephone}
                    setTheInput={setTelephone}
                    inputStyle={inputStyle}
                    rows={1}
                    hasError={false}
                    autocomplete={"tel"}
                  />
                </div>
                <h1 className={headerStyle}>Projektinformationen</h1>
                <div className="flex flex-col m-4 gap-y-4">
                  {/* projectIdea */}
                  <InputField
                    isTextarea={true}
                    isCheckbox={false}
                    labelFor={"projectIdea"}
                    labelName={
                      "Bitte erläutern Sie ihre Projektidee und beschreiben Sie die Initiative"
                    }
                    isRequired={true}
                    labelStyle={labelStyle}
                    inputType={"text"}
                    inputName={"projectIdea"}
                    inputId={"projectIdea"}
                    theInput={projectIdea}
                    setTheInput={setTheInputProjectIdea}
                    inputStyle={inputStyle}
                    maxLength={2000}
                    rows={4}
                    count={counterProjectIdea}
                    hasError={false}
                  />
                  {/* region */}
                  <InputField
                    isTextarea={true}
                    isCheckbox={false}
                    labelFor={"region"}
                    labelName={
                      "Wo wird das Projekt / die Initiative durchgeführt (in welchem Stadtteil, überall in Münster oder regional / überregional)?"
                    }
                    isRequired={true}
                    labelStyle={labelStyle}
                    inputType={"text"}
                    inputName={"region"}
                    inputId={"region"}
                    theInput={region}
                    setTheInput={setTheInputRegion}
                    inputStyle={inputStyle}
                    maxLength={1000}
                    rows={4}
                    count={counterRegion}
                    hasError={false}
                  />
                  {/* startDate */}
                  <InputField
                    isTextarea={false}
                    isCheckbox={false}
                    labelFor={"startDate"}
                    labelName={
                      "Seit wann führen Sie das Projekt / die Initiative durch?"
                    }
                    isRequired={true}
                    labelStyle={labelStyle}
                    inputType={"date"}
                    inputName={"startDate"}
                    inputId={"startDate"}
                    theInput={startDate}
                    setTheInput={setStartDate}
                    inputStyle={inputStyle}
                    rows={1}
                    hasError={false}
                  />
                  {/* currentVolunteerCount */}
                  <InputField
                    isTextarea={false}
                    isCheckbox={false}
                    labelFor={"currentVolunteerCount"}
                    labelName={
                      "Wie viele Ehrenamtliche wirken in dem Projekt / der Initiative mit?"
                    }
                    isRequired={true}
                    labelStyle={labelStyle}
                    inputType={"text"}
                    inputName={"currentVolunteerCount"}
                    inputId={"currentVolunteerCount"}
                    theInput={currentVolunteerCount}
                    setTheInput={setTheInputCurrentVolunteerCount}
                    inputStyle={inputStyle}
                    maxLength={8}
                    rows={1}
                    count={counterCurrentVolunteerCount}
                    hasError={true}
                    error={errorCurrentVolunteerCount}
                  />
                  {/* entireVolunteerCount*/}
                  <InputField
                    isTextarea={false}
                    isCheckbox={false}
                    labelFor={"entireVolunteerCount"}
                    labelName={
                      "Wie viele Ehrenamtliche haben das Projekt in den letzten Jahren getragen?"
                    }
                    isRequired={true}
                    labelStyle={labelStyle}
                    inputType={"text"}
                    inputName={"entireVolunteerCount"}
                    inputId={"entireVolunteerCount"}
                    theInput={entireVolunteerCount}
                    setTheInput={setTheInputEntireVolunteerCount}
                    inputStyle={inputStyle}
                    maxLength={8}
                    rows={1}
                    count={counterEntireVolunteerCount}
                    hasError={true}
                    error={errorEntireVolunteerCount}
                  />
                  {/* currentEmployeeCount */}
                  <InputField
                    isTextarea={false}
                    isCheckbox={false}
                    labelFor={"currentEmployeeCount"}
                    labelName={
                      "Wie viele bezahlte Kräfte unterstützen das Projekt?"
                    }
                    isRequired={true}
                    labelStyle={labelStyle}
                    inputType={"text"}
                    inputName={"currentEmployeeCount"}
                    inputId={"currentEmployeeCount"}
                    theInput={currentEmployeeCount}
                    setTheInput={setTheInputCurrentEmployeeCount}
                    inputStyle={inputStyle}
                    maxLength={8}
                    rows={1}
                    count={counterCurrentEmployeeCount}
                    hasError={true}
                    error={errorCurrentEmployeeCount}
                  />
                  {/* occasion */}
                  <InputField
                    isTextarea={true}
                    isCheckbox={false}
                    labelFor={"occasion"}
                    labelName={
                      "Was war der Anlass des Projektes/der Initiative?"
                    }
                    isRequired={true}
                    labelStyle={labelStyle}
                    inputType={"text"}
                    inputName={"occasion"}
                    inputId={"occasion"}
                    theInput={occasion}
                    setTheInput={setTheInputOccasion}
                    inputStyle={inputStyle}
                    maxLength={2000}
                    rows={4}
                    count={counterOccasion}
                    hasError={false}
                  />
                  {/* projectAim */}
                  <InputField
                    isTextarea={true}
                    isCheckbox={false}
                    labelFor={"projectAim"}
                    labelName={
                      "Was soll mit dem Projekt / der Initiative erreicht werden?"
                    }
                    isRequired={true}
                    labelStyle={labelStyle}
                    inputType={"text"}
                    inputName={"projectAim"}
                    inputId={"projectAim"}
                    theInput={projectAim}
                    setTheInput={setTheInputProjectAim}
                    inputStyle={inputStyle}
                    maxLength={2000}
                    rows={4}
                    count={counterProjectAim}
                    hasError={false}
                  />
                  {/* cooperationPartner */}
                  <InputField
                    isTextarea={true}
                    isCheckbox={false}
                    labelFor={"cooperationPartner"}
                    labelName={"Gibt es Kooperationspartner?"}
                    isRequired={true}
                    labelStyle={labelStyle}
                    inputType={"text"}
                    inputName={"cooperationPartner"}
                    inputId={"cooperationPartner"}
                    theInput={cooperationPartner}
                    setTheInput={setTheInputCooperationPartner}
                    inputStyle={inputStyle}
                    maxLength={500}
                    rows={3}
                    count={counterCooperationPartner}
                    hasError={false}
                  />
                  {/* achievedSoFar */}
                  <InputField
                    isTextarea={true}
                    isCheckbox={false}
                    labelFor={"achievedSoFar"}
                    labelName={"Was haben Sie bisher erreicht?"}
                    isRequired={true}
                    labelStyle={labelStyle}
                    inputType={"text"}
                    inputName={"achievedSoFar"}
                    inputId={"projeachievedSoFarctIdea"}
                    theInput={achievedSoFar}
                    setTheInput={setTheInputAchievedSoFar}
                    inputStyle={inputStyle}
                    maxLength={2000}
                    rows={4}
                    count={counterAchievedSoFar}
                    hasError={false}
                  />
                  {/* projectFinancing */}
                  <InputField
                    isTextarea={true}
                    isCheckbox={false}
                    labelFor={"projectFinancing"}
                    labelName={
                      "Wie wird das Projekt finanziert, sofern es nicht rein ehrenamtlich umgesetzt wird?"
                    }
                    isRequired={true}
                    labelStyle={labelStyle}
                    inputType={"text"}
                    inputName={"projectFinancing"}
                    inputId={"projectFinancing"}
                    theInput={projectFinancing}
                    setTheInput={setTheInputProjectFinancing}
                    inputStyle={inputStyle}
                    maxLength={2000}
                    rows={4}
                    count={counterProjectFinancing}
                    hasError={false}
                  />
                  {/* projectUSP */}
                  <InputField
                    isTextarea={true}
                    isCheckbox={false}
                    labelFor={"projectUSP"}
                    labelName={
                      "Was ist das Besondere, Beispielhafte des Projektes / der Initiative?"
                    }
                    isRequired={true}
                    labelStyle={labelStyle}
                    inputType={"text"}
                    inputName={"projectUSP"}
                    inputId={"projectUSP"}
                    theInput={projectUSP}
                    setTheInput={setTheInputProjectUSP}
                    inputStyle={inputStyle}
                    maxLength={2000}
                    rows={4}
                    count={counterProjectUSP}
                    hasError={false}
                  />
                  {/* projectOutlook */}
                  <InputField
                    isTextarea={true}
                    isCheckbox={false}
                    labelFor={"projectOutlook"}
                    labelName={
                      "Wie wird sich Ihr Projekt / Ihre Initiative in fünf Jahren gestalten? Wie wird das Projekt / die Initiative dann aussehen bzw. wirken?"
                    }
                    isRequired={true}
                    labelStyle={labelStyle}
                    inputType={"text"}
                    inputName={"projectOutlook"}
                    inputId={"projectOutlook"}
                    theInput={projectOutlook}
                    setTheInput={setTheInputProjectOutlook}
                    inputStyle={inputStyle}
                    maxLength={2000}
                    rows={4}
                    count={counterProjectOutlook}
                    hasError={false}
                  />
                  {/* Image upload */}
                  <label className={labelStyle}>
                    <div>
                      {"Laden Sie passende Bider zu Ihrem Projekt hoch:"}
                    </div>
                    {imageCount !== undefined && (
                      <>
                        {imageCount === maxImages ? (
                          <div className="text-red-600">
                            {imageCount + "/" + maxImages} Bildern
                          </div>
                        ) : (
                          <div>{imageCount + "/" + maxImages} Bildern</div>
                        )}
                      </>
                    )}
                  </label>
                  <ImageUpload
                    images={images}
                    setImages={setImages}
                    maxImages={maxImages}
                  />
                  {/* ToSAgreement */}
                  <InputField
                    isTextarea={false}
                    isCheckbox={true}
                    labelFor={"ToSAgreement"}
                    labelName={
                      "Wir sind mit der Veröffentlichung unseres Projektes im Kontext des Bürgerpreises einverstanden"
                    }
                    isRequired={true}
                    labelStyle={checkbox_labelStyle}
                    inputType={"checkbox"}
                    inputName={"ToSAgreement"}
                    inputId={"ToSAgreement"}
                    theInput={ToSAgreement}
                    setTheInput={setToSAgreement}
                    inputStyle={checkbox_inputStyle}
                    hasError={false}
                  />
                  {/* marketingChannel */}
                  <InputField
                    isTextarea={true}
                    isCheckbox={false}
                    labelFor={"marketingChannel"}
                    labelName={
                      "Wie sind Sie auf den Bürgerpreis aufmerksam geworden?"
                    }
                    isRequired={false}
                    labelStyle={labelStyle}
                    inputType={"text"}
                    inputName={"marketingChannel"}
                    inputId={"marketingChannel"}
                    theInput={marketingChannel}
                    setTheInput={setTheInputMarketingChannel}
                    inputStyle={inputStyle}
                    maxLength={500}
                    rows={3}
                    count={counterMarketingChannel}
                    hasError={false}
                  />
                </div>
                <Button
                  type="submit"
                  buttonText="Abschicken"
                  bgColor="red-600"
                  bgHoverColor="red-700"
                  borderColor="transparent"
                  textColor="white"
                  fontWeight="medium"
                  px="8"
                  py="3"
                  mt="6"
                  ml="0"
                  width="full"
                  ringColor="red-500"
                />
              </form>
            )}
          </div>
        </div>
      </div>
      <a
        href="https://spring-board.dev"
        className="text-sm text-gray-500 flex items-center my-12 py-4 px-16"
      >
        powered by
        <img
          src={SpringboardLogo}
          width="130"
          alt="Logo"
          className="my-auto cursor-pointer pl-2"
        />
      </a>
    </div>
  );
}
