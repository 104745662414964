import axios from "axios";

// const baseURL = "http://localhost:7071/api";
const baseURL = "https://buerger-preis-muenster.azurewebsites.net/api";

export default axios.create({
  baseURL: baseURL,
  timeout: 20000,
  headers: {
    Authorization: localStorage.getItem("token")
      ? "Bearer " + localStorage.getItem("token")
      : sessionStorage.getItem("token")
      ? "Bearer " + sessionStorage.getItem("token")
      : null,
    "Content-Type": "application/json",
    "x-functions-key":
      "TkaKvaLliMP5t9sXsieHwxeHemQ6wsYL9Fvs1qdzU5oCAzFu45Kz2A==",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Header":
      "Origin, X-Requested-With, Content-Type, Accept",
  },
});
