import axios from "axios";

class imageService {
  saveImage(imageBase64) {
    const encodedParams = new URLSearchParams();
    encodedParams.append("image", imageBase64.dataURL.substring(22)); // ugly as hell, but this works
    encodedParams.append("type", "base64");

    const options = {
      method: "POST",
      url: "https://imgur-apiv3.p.rapidapi.com/3/image",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        Authorization: "Client-ID 1178456e8841f36",
        "X-RapidAPI-Key": "0d0372621bmsh68edb9d4dedd0cfp10eac5jsnf52340ba11b7",
        "X-RapidAPI-Host": "imgur-apiv3.p.rapidapi.com",
      },
      data: encodedParams,
    };

    const request = axios.request(options);
    return Promise.resolve(request);
  }
}

export default new imageService();
